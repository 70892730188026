<template>
  <both-colum-page
      :title="$t('companyOemManage.title')"
      :formData="formData"
      @functionMethod="functionMethod"
      @search="search"
  >
    <template v-slot:left-title>{{ $t('companyOemManage.leftTitle') }}</template>
    <template v-slot:left>
      <ul style="margin-top: 10px">
        <li v-for="item in productTypeEnum" :key="item.value">
          <el-button
              style="width: 100%; margin-top: 20px"
              :type="item.value === params.productType ? 'primary' : ''"
              @click="selectProductType(item)"
          >
            {{ item.label }}
          </el-button>
        </li>
      </ul>
    </template>
    <table-list
        ref="tableList"
        :url="$api.oemConfigFindList"
        :highlightCurrentRow="false"
        :columns="columns"
        :defaultParams="params"
        v-model="tableRows"
    >
      <template v-slot:targetCompany="{ row }">
        {{row.companyData.name}}
      </template>
      <template v-slot:productName="{ row }">
        <div v-if="row.productZhName">{{row.productZhName}}</div>
        <br />
        <div v-if="row.productEnName">{{row.productEnName}}</div>
      </template>
      <template v-slot:productLogo="{ row }">
        <el-image style="height: 80px" :fit="'contain'" v-if="row.productLogoZhImg" :src="row.productLogoZhImg.fileHost + '/' + row.productLogoZhImg.objectName" />
        <br />
        <el-image style="height: 80px" :fit="'contain'" v-if="row.productLogoEnImg" :src="row.productLogoEnImg.fileHost + '/' + row.productLogoEnImg.objectName" />
      </template>
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <template v-slot:status="{ row }">
        {{ $t('businessEnum.' + row.enable) }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:修改 -->
        <el-button
            v-if="$page.showBtn('COMPANY_OEM_MANAGE_CHANGE') && row.enable === 'NORMAL'"
            type="text"
            @click="editMethod(row)"
        >
          {{ $t('functionEnum.COMPANY_OEM_MANAGE_CHANGE') }}
        </el-button>
        <!-- lang:删除 -->
        <el-button
            v-if="$page.showBtn('COMPANY_OEM_MANAGE_DEL') && row.enable === 'NORMAL'"
            type="text"
            @click="deleteMethod(row)"
        >
          {{ $t('functionEnum.COMPANY_OEM_MANAGE_DEL') }}
        </el-button>

        <!-- lang:恢复 -->
        <el-button
            v-if="$page.showBtn('COMPANY_OEM_MANAGE_RECOVER') && row.enable === 'DELETE'"
            type="text"
            @click="recoverMethod(row)"
        >
          {{ $t('functionEnum.COMPANY_OEM_MANAGE_RECOVER') }}
        </el-button>
        <!-- lang:显示 -->
        <el-button
            v-if="$page.showBtn('COMPANY_OEM_MANAGE_VIEW')"
            type="text"
            @click="viewMethod(row)"
        >
          {{ $t('functionEnum.COMPANY_OEM_MANAGE_VIEW') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" :productType="params.productType" @closePage="closePage" />
    <dialog-import
        v-if="showDialogImport"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.templateImport"
        :show-tips="false"
        :accept="'.template'"
        @cancel="showDialogImport = false"
        @confirm="importConfirm"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'
import {oemConfigRecover} from "@/api";

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      // 显示表单
      showDetail:false,
      showDialogImport:false,
      productTypeEnum: this.$dict.productTypeEnum(),
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        companyUid: null,
        productType: 'TMVA',
      },
      columns: [
        {
          label: this.$t('companyOemManage.targetCompany'),
          prop: 'targetCompany',
        },
        {
          label: this.$t('companyOemManage.productName'),
          prop: 'productName'
        },
        {
          label: this.$t('companyOemManage.productLogo'),
          prop: 'productLogo',
        },
        // lang:创建时间
        {
          label: this.$t('companyOemManage.status'),
          prop: 'status',
        },
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
        },
      ],
    }
  },
  mounted() {
    // this.dictFindTree()
  },
  methods: {
    selectProductType(item) {
      this.params.productType = item.value
      this.search()
    },
    // 关闭详情页回调
    closePage(cmd) {
      this.showDetail = false
      this.showRenewal = false
      this.search()
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'import' && method !== 'add' && this.tableRows.length === 0) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'export':
          this.exportMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    editMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'update'
      this.showDetail = true
    },
    viewMethod(row) {
      this.tableRow = row
      this.$store.state.cmd = 'view'
      this.showDetail = true
    },
    exportMethod() {
      const ids = this.tableRows.map(item => item.id)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.templateExport,
        data: {ids}
      }).then(res => {
        window.location.href = res
      })
    },
    // 导入成功回掉
    importConfirm() {
      this.showDialogImport = false
      this.search()
    },
    deleteMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: this.$api.oemConfigDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
    recoverMethod(row) {
      this.tableRow = row
      this.$element
          .showConfirm({
            content: this.$t('base.recoverConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: this.$api.oemConfigRecover,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.recoverSuccess'), 'success')
              this.search()
            })
          })
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
  },
}
</script>
